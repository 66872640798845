/**
 * Direttiva che costruisce la barra di ricerca
 */
import { Item, User } from "atfcore-commonclasses";

export interface ISearchBarDirectiveScope extends ng.IScope {
	libraryCard: any;
	goToItemDetail: Function;
	chartOptions: any;
	isSoftSkill: boolean;
	isTechSkill: boolean;
	itemType: any;
	hourValue: string;
	techCompetences: any;
	softCompetences: any;
	hasSuggested: boolean;
	globalApplicationData: any;
	bgacademyApplicationData: any;
	suggestedPerson: any;
	isLearningPlan: boolean;
	page: any;
	item: any;
	isItemOpen: boolean;
	percentageItem: boolean;
	suggestedFromAdmin: boolean;
	isFilterToolbarExpanded: boolean;
	expandFilterToolbar: Function;
	tabOneTitle: string;
	tabTwoTitle: string;
	tabThreeTitle: string;
	tabFourTitle: string;
	inputSearchPlaceholder: string;
	removeFilters: Function;
	hasTabsAtLeastOneFilter: Function;
	countTabFilters: Function;
	filters: any;
	selectedIndex: number;
	competenceList: Array<any>;
	creditTypeList: Array<any>;
	bienniumList: Array<any>;
	courseTypeList: Array<any>;
	haveSameId: Function;
	tabChanged: Function;
	creditTypeChanged: Function;
	getBienniumList: Function;
	avaiableYears: Array<string>;
	tabs: Array<any>;
}
angular.module('app').directive("searchBar", ($window, $state, BgacademyApplicationData, GlobalApplicationData, $translate) => {
	return {
		restrict: 'AE',
		scope: {
			page: '=', // pagina corrente
			tabs: '=', // tab dinamiche da costruire
			toggleCardResultView: '&', // toggle risultati card/lista
			isCardResultView: '=', // controlla in quale modalità di visualizzazione sono 
			search: '=', // avvia la ricerca
			creditTypeList: '=', // lista di crediti disponibili
			courseTypeList: '=', // lista di corsi disponibili
			filters: '=', // filtri della ricerca
			bienniumList: '=', // lista di bienni dell'iscrizione rui
			getBienniumList: '=', // recupera la lista dei bienni
			searchCourses: '=',
			bienniumYearsList: '='
		},
		link: link,
		templateUrl: 'app/shared/searchBar/searchBar.html'
	};
	function link($scope: ISearchBarDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		$scope.selectedIndex = 0;

		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		// Titolo in traduzione delle quattro Tab nella barra dei filtri
		$scope.tabOneTitle = $translate.instant("trainingPlan.tabs.title.ALL");
		$scope.tabTwoTitle = $translate.instant("trainingPlan.tabs.title.MANAGERIAL");
		$scope.tabThreeTitle = $translate.instant("trainingPlan.tabs.title.NORMATIVE");
		$scope.tabFourTitle = $translate.instant("trainingPlan.tabs.title.TECH");

		// Traduzione del placeholder dell'input di ricerca 
		$scope.inputSearchPlaceholder = $translate.instant("trainingPlan.tabs.filters.inputSearchPlaceholder");

		// Controlla l'espansione della barra dei filtri
		$scope.isFilterToolbarExpanded = false;
		// Espande la barra dei filtri
		$scope.expandFilterToolbar = () => {
			$scope.isFilterToolbarExpanded = !$scope.isFilterToolbarExpanded;
		}
		
		// Verifica se due oggetti sono uguali
		$scope.haveSameId = (obj1: any, obj2: any) => {
			if (obj1 && obj2) {
				return obj1.id === obj2.id;
			}
		}

		$scope.avaiableYears = [];
		// Caso in cui ho già selezionato la tipologia mifid
		if ($scope.filters.bienniumYear && $scope.filters.creditType && $scope.haveSameId($scope.filters.creditType, $scope.creditTypeList[5])) {
			$scope.avaiableYears.length = 0;
			var firstYear = parseInt("2016", 10);
			let currentYear = new Date().getFullYear();
			$scope.avaiableYears.push($translate.instant("generic.ALL"));
			while (firstYear <= currentYear) {
				// Li inserisco nel contenitore di anni dispnibili per la select
				$scope.avaiableYears.push("" + firstYear);
				firstYear++;
			}
		}

		// Se è stato selezionato un credito ivass, avvio la ricerca dei bienni
		$scope.creditTypeChanged = () => {
			if ($scope.bienniumList && $scope.haveSameId($scope.filters.creditType, $scope.creditTypeList[1])) {
				$scope.getBienniumList();
			} else {
				if ($scope.haveSameId($scope.filters.creditType, $scope.creditTypeList[5])) {
					$scope.avaiableYears.length = 0;

					var firstYear = parseInt("2016", 10);
					let currentYear = new Date().getFullYear();
					$scope.avaiableYears.push($translate.instant("generic.ALL"));
					$scope.filters.bienniumYear = $scope.avaiableYears[0];
					while (firstYear <= currentYear) {
						// Li inserisco nel contenitore di anni dispnibili per la select
						$scope.avaiableYears.push("" + firstYear);
						firstYear++;
					}
				}
			}
		}

		// Quando cambia il biennio selezionato, devo cambiare anche la lista degli anni disponibili
		$scope.$watch('filters.biennium', (newValue, oldValue) => {
			if (newValue && $scope.filters && $scope.filters.biennium && $scope.filters.biennium.id && $scope.filters.biennium.id != 1) {
				// Innanzitutto pulisco il contenitore degli anni disponibili nella select, affinché i nuovi risultati non vengano aggiunti a quelli precedenti
				$scope.avaiableYears.length = 0;

				// Divido il biennio in due, ricavando il primo e il secondo anno
				var yearsInSelectedBiennium = $scope.filters.biennium.name.split('-');

				var firstYear = yearsInSelectedBiennium[0];
				var secondYear = yearsInSelectedBiennium[1];

				// Li inserisco nel contenitore di anni dispnibili per la select
				$scope.avaiableYears.push(firstYear);
				!!secondYear && $scope.avaiableYears.push(secondYear);

				// Eseguo la preselezione sul primo anno
				$scope.filters.bienniumYear = firstYear;
			}
		}, true);

		// Azzera i filtri
		$scope.removeFilters = () => {
			$scope.filters.searchedText = '';
			$scope.filters.creditType = $scope.filters.creditType && $scope.creditTypeList[0];
			$scope.filters.biennium = $scope.filters.biennium && $scope.bienniumList[0];
			$scope.filters.bienniumYear = $scope.bienniumList && $scope.bienniumList.length && $scope.bienniumList[0].years && $scope.bienniumList[0].years.length && $scope.bienniumList[0].years[0];
			$scope.filters.courseType = $scope.filters.courseType && $scope.courseTypeList[0];
			$scope.filters.openedTab = 0;
			$scope.filters.isCourseExtraTab = false;
		}

		// Verifica che ci sia almeno un filtro attivo
		$scope.hasTabsAtLeastOneFilter = () => {
			if (!$scope.haveSameId($scope.filters.creditType, $scope.creditTypeList[0])
				|| ($scope.bienniumList && !$scope.haveSameId($scope.filters.biennium, $scope.bienniumList[0]))
				|| ($scope.courseTypeList && !$scope.haveSameId($scope.filters.courseType, $scope.courseTypeList[0]))) {
				return true;
			}
			return false;
		}

		// Conta il numero di filtri attivi
		$scope.countTabFilters = () => {
			let count: number = 0;
			if ($scope.bienniumList && !$scope.haveSameId($scope.filters.biennium, $scope.bienniumList[0])) {
				count++;
			}
			if (!$scope.haveSameId($scope.filters.creditType, $scope.creditTypeList[0])) {
				count++;
			}
			if ($scope.courseTypeList && !$scope.haveSameId($scope.filters.courseType, $scope.courseTypeList[0])) {
				count++;
			}
			if ($scope.haveSameId($scope.filters.creditType, $scope.creditTypeList[5]) && $scope.filters.bienniumYear && !isNaN($scope.filters.bienniumYear)) {
				count++;
			}
			return count;
		}

		// Tiene traccia della tab corrent
		$scope.tabChanged = (tab: any) => {
			// Salvo il numero di tab
			$scope.filters.openedTab = tab;
			// Se sono nella pagina del piano formativo, controllo se la tab selezionata è quella dei corsi extra
			let extraTab = $scope.tabs && $scope.tabs.length;
			if ($scope.page == $scope.bgacademyApplicationData.constants.TRAINING_PLAN && tab == extraTab) {
				// Poichè sono nella tab dei corsi extra del piano formativo, e il filtro per 'IVASS' viene nascosto, resetto il filtro per tipologia di credito. Difatti, l'utente potrebbe aver scelto
				// il credito IVASS nella tab precedente
				$scope.filters.creditType = $scope.filters.creditType && $scope.creditTypeList[0];
				$scope.filters.isCourseExtraTab = $scope.tabs[tab - 1].isCourseExtraTab;
			}
		}
	}
});